<template>
  <div>
    <div class="staff">
      <el-menu
        default-active="1-0"
        class="el-menu-vertical-demo"
        :unique-opened="true">
        <el-submenu index="1">
          <template slot="title">
            <span>PC端</span>
          </template>
          <el-menu-item
            v-for="(item,index) in tabConfigPC"
            :key="index"
            :index="'1-'+index"
            @click="clickMenuItem(item)">
            {{item.name}}
          </el-menu-item>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <span>移动端</span>
          </template>
          <el-menu-item
            v-for="(item,index) in tabConfigH5"
            :key="index"
            :index="'2-'+index"
            @click="clickMenuItem(item)">
            {{item.name}}
          </el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <span>APP端</span>
          </template>
          <el-menu-item
            v-for="(item,index) in tabConfigAPP"
            :key="index"
            :index="'3-'+index"
            @click="clickMenuItem(item)">
            {{item.name}}
          </el-menu-item>
          
        </el-submenu>
        <el-submenu index="4">
          <template slot="title">
            <span>PC端 - 政策与支持</span>
          </template>
           <el-menu-item
            v-for="(item,index) in newsData"
            :key="index"
            :index="'4-'+index"
            @click="clickMenuItem(item)">
            {{item.name}}
          </el-menu-item>
        </el-submenu>
         <el-submenu index="5">
          <template slot="title">
            <span>移动端 - 政策与支持</span>
          </template>
           <el-menu-item
            v-for="(item,index) in mNewsData"
            :key="index"
            :index="'5-'+index"
            @click="clickMenuItem(item)">
            {{item.name}}
          </el-menu-item>
        </el-submenu>
      </el-menu>
      <el-main>
        <Adpage ref="adpage" :tabContent="tabContent"></Adpage>
      </el-main>
    </div>
  </div>
</template>
<script>
import Adpage from "../../components/Adpage";
export default {
  components: {
    Adpage,
  },
  data(){
    return {
      // 标签页配置
      tabConfigPC:[
        // { name: "热点标签", type: 0, min: 1, maxNum: 10 },
        { name: "新手学堂PC端", type: 23, min: 1, maxNum: 5, size: "1920*420" },
        { name: "PC端banner下方区域", type: 21, min: 1, maxNum: 4, size: "40*40", isDescription:true },
        { name: "PC端胶囊banner", type: 25, min: 0, maxNum: 1, size: "1920*420" },
        { name: "官网首页", type: 1, min: 1, maxNum: 6, size: "1920*500", isShowNotice:true },  // 两张写死，故可配置6张
        { name: "政策资讯页", type: 2, min: 1, maxNum: 4, size: "830*300" },
        { name: "社保代缴产品页", type: 3, min: 1, maxNum: 1, size: "1920*480", isShowNotice:true },  // 一张写死，故可配置3张
        { name: "社保托管产品页", type: 20, min: 1, maxNum: 1, size: "1920*480" },  // 一张写死，故可配置3张
        { name: "电子工资条产品页", type: 4, min: 1, maxNum: 1, size: "1920*480" },
        { name: "薪资代发产品页", type: 5, min: 1, maxNum: 1, size: "1920*480" },
        { name: "花名册产品页", type: 8, min: 1, maxNum: 1, size: "1920*480" },
        { name: "智能入职产品页", type: 26, min: 1, maxNum: 1, size: "1920*420" },
        { name: "员工商保", type: 30, min: 0, maxNum: 1, size: "1920*420" },
        { name: "积分福利", type: 32, min: 0, maxNum: 1, size: "1920*420" },
        { name: "移动考勤", type: 34, min: 0, maxNum: 1, size: "1920*420" },
        { name: "注册页", type: 6, min: 1, maxNum: 1, size: "698*445" },
        { name: "登录页", type: 7, min: 1, maxNum: 1, size: "698*445" },
        { name: "活动登录页", type: 11, min: 1, maxNum: 1, size: "415*599" },
        { name: "节日福利产品页", type: 12, min: 1, maxNum: 6, size: "1920*500" },
        { name: "用户中心", type: 28, min: 1, maxNum: 3, size: "1920*420" },
        { name: "移动考勤", type: 34, min: 1, maxNum: 3, size: "1920*420" },
        { name: "员工学习", type: 36, min: 1, maxNum: 3, size: "1920*420" },
        { name: "员工体检", type: 38, min: 1, maxNum: 3, size: "1920*420" },
      ],
      tabConfigH5:[
        { name: "新手学堂移动端", type: 24, min: 1, maxNum: 5, size: "750*320" },
        { name: "移动端banner下方区域", type: 22, min: 1, maxNum: 4, size: "40*40" },
        { name: "移动端首页", type: 9, min: 1, maxNum: 4, size: "375*282" },
        { name: "社保代缴（移动端）", type: 14, min: 1, maxNum: 1, size: "375*160" },
        { name: "社保账户托管（移动端）", type: 15, min: 1, maxNum: 1, size: "375*160" },
        { name: "电子工资条（移动端）", type: 16, min: 1, maxNum: 1, size: "375*160" },
        { name: "员工花名册（移动端）", type: 17, min: 1, maxNum: 1, size: "375*160" },
        { name: "节日福利（移动端）", type: 18, min: 1, maxNum: 1, size: "375*160" },
        { name: "薪资代发（移动端）", type: 19, min: 1, maxNum: 1, size: "375*160" },
        { name: "智能入职(移动端)", type: 27, min: 1, maxNum: 1, size: "1920*420" },
        { name: "员工商保（移动端）", type: 31, min: 0, maxNum: 1, size: "1920*420" },
        { name: "积分福利(移动端)", type: 33, min: 0, maxNum: 1, size: "1920*420" },
        { name: "移动考勤(移动端)", type: 35, min: 0, maxNum: 1, size: "1920*420" },
        { name: "员工学习(移动端)", type: 37, min: 1, maxNum: 3, size: "1920*420" },
        { name: "员工体检(移动端)", type: 39, min: 1, maxNum: 3, size: "1920*420" },
      ],
      tabConfigAPP:[
        // { name: "app端首页", type: 10, min: 1, maxNum: 4, size: "750*494" },
        { name: "app活动", type: 13, min: 1, maxNum: 6, size: "1920*500" },
        { name: "APP右侧浮窗图标", type: 29, min: 0, maxNum: 1, size: "1920*420" },
      ],
      newsData:[],
      mNewsData:[],
      tabContent:null
    }
  },
  created(){
    this.getNewsDate();
  },
  mounted(){
    this.tabContent = this.tabConfigPC[0];
    this.$refs.adpage.initList(this.tabContent.type);
  },
  methods:{
    //获取数据
    getNewsDate() {
        this.$get(this.$api.GetArticleCategoryData).then((res) => {
            //console.log(res.Data);
            let news = res.Data;
            let newslist = [];
            let mnewslist=[];
            news.forEach(item => {
              newslist.push({ name: item.Name, type: item.Id*1000, min: 0, maxNum: 1, size: "350*180" },)
              mnewslist.push({ name: item.Name, type: item.Id*2000, min: 0, maxNum: 1, size: "350*140" },)
            });
            this.newsData=newslist;
            this.mNewsData=mnewslist;
        });
    },
    clickMenuItem(item) {
      this.tabContent = item;
      this.$refs.adpage.initList(this.tabContent.type);
    },
  }
};
</script>
<style lang="stylus" scoped>
.staff {
  display: flex;
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;
}
.el-menu {
  flex: 0 0 200px;
  height: 100%;
  max-height: 100vh;
  min-height: 720px;
  overflow: auto;
  background: #fff;

  .el-submenu .el-menu-item {
    min-width: 100px;
  }
}
.el-main {
  flex:1;
  background: #fff;
}
</style>